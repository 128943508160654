// import the library
import { library } from '@fortawesome/fontawesome-svg-core'

// import your icons
import {
  faArrowDown,
  faArrowUp,
  faAt,
  faBackward,
  faBackwardFast,
  faBars,
  faBasketShopping,
  faBookOpenReader,
  faCalendarDays,
  faCheck,
  faDollarSign,
  faEdit,
  faExclamationCircle,
  faEllipsisVertical,
  faForward,
  faForwardFast,
  faFileContract,
  faFileCsv,
  faGear,
  faInfoCircle,
  faKey,
  faLock,
  faNewspaper,
  faPencil,
  faPenToSquare,
  faPeopleArrows,
  faPlus,
  faRobot,
  faRotate,
  faTrashCan,
  faTruckFast,
  faUser,
  faUserCircle,
  faUserGraduate,
  faXmark,
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faArrowDown,
  faArrowUp,
  faAt,
  faBackward,
  faBackwardFast,
  faBars,
  faBasketShopping,
  faBookOpenReader,
  faCalendarDays,
  faCheck,
  faDollarSign,
  faEdit,
  faExclamationCircle,
  faEllipsisVertical,
  faFileContract,
  faFileCsv,
  faForward,
  faForwardFast,
  faGear,
  faKey,
  faInfoCircle,
  faLock,
  faNewspaper,
  faPencil,
  faPenToSquare,
  faPeopleArrows,
  faPlus,
  faRobot,
  faRotate,
  faTrashCan,
  faTruckFast,
  faUser,
  faUserCircle,
  faUserGraduate,
  faXmark
)
